<template>
  <div class="bgtype minwidth mw100" style="z-index: 99">
    <div class="flex flex-bt tabHeight">
      
      <div class="h100 flex h50 paddingrig disnone" >
        <div v-for="(item,index) in tabList" :key="item.name" class="tab margincentY" @click="skip(item.url,index)">
          <el-button class="tabFont" type="text">{{ item.name }}</el-button>
        </div>
      </div>
      <div class="h100 disnone2">
        <el-button class="h100 tabListBut" @click="showList = true" type="text" icon="el-icon-s-operation"></el-button>
      </div>

      <div class="h100 mlogo" style="padding-left: 5rem;">
        <img :src="logo" alt="" class="logoheight" @click="main" />
      </div>
    </div>
    <div v-if="showList" style="
        background-color: #fff;
        width: 70%;
        height: 100vh;
        position: fixed;
        top: 0;
        z-index: 10;
      " class="disnone2">
      <div>
        <el-button style="margin-top: 1vh; margin-left: 2vw;" @click="showList = false" circle size="medium"
          icon="el-icon-close"></el-button>
      </div>
      <div v-for="(item,index) in tabList" :key="item.name" style="width: 80%; margin: 0 auto; text-align: center"
        @click="skip(item.url,index)">
        <el-button class="tabFont" type="text">{{ item.name }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../../public/img/tabbar/logoComposite2.png"),

        tabNow: 0,
        tabList: [
          { name: "首页", url: "/" },
          { name: "关于我们", url: "/partner" },
          { name: "学习乐园", url: "/learn" },
          { name: "行业服务案例", url: "/trends" },
          // { name: "行业咨询", url: "/news" },
          { name: "联系我们", url: "/join" },
          { name: "登录", url: "/login" },
        ],

        isMobile: false,

        showList: false,
      };
    },

    methods: {
      main() {
        this.$router.push("/");
      },
      skip(url, index) {
        // this.tabNow = index
        this.$router.push(url);
      },
    },
  };
</script>

<style scoped>
  .bgtype {
    /* background-color: transparent; */
    /* background-color: #720707; */
    background: linear-gradient(to bottom, #014a97, #020308);
    width: 100%;
    height: auto;
    z-index: 99;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .flex-bt {
    justify-content: space-between;
  }

  .h100 {
    height: 100%;
  }

  .w100 {
    width: 100%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .logowidth {
    width: 280px;
  }

  .logoheight {
    height: 70%;
    margin-top: 1rem;
  }

  .margincentY {
    margin: auto 0;
  }

  .paddingrig {
    padding-right: 10%;
  }

  .tab {
    margin-left: 30px;
  }

  .tabFont {
    font-weight: bolder;
    font-size: 18px;
    line-height: 50px;
    /* color: rgb(233 192 131); */
    /* color: #333 !important; */
    color: #fff !important;
    background-color: transparent;
    border-style: none;
  }

  .tabHeight {
    height: 100px;
  }

  .disnone2 {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .flexnone {
      display: block;
    }

    .mw100 {
      width: 100vw;
    }

    .tab {
      margin-left: 0px;
    }

    .bgtype {
      background-color: transparent;
      width: 80%;
      height: auto;
      z-index: 99;
      margin: 0 auto;
    }

    .tabFont {
      font-weight: bold;
      font-size: 16px;
      line-height: 50px;
      color: #000 !important;
      background-color: transparent;
      border-style: none;
    }

    .tabHeight {
      height: 8vh;
    }

    .mlogo {
      height: 100%;
      text-align: left;
    }

    .h50 {
      height: 50px;
    }

    .paddingrig {
      padding-right: 0;
    }

    .disnone {
      display: none;
    }

    .disnone2 {
      display: block;
    }

    .tabListBut,
    .tabListBut:hover {
      width: 10vw;
      margin-right: 2vw;
      font-size: 8vw;
      color: rgb(233 192 131);
    }

  }
</style>