<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div style="width: 100%;margin: 0 auto;">
      <div class="positionrel w100">
        <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
        <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);width: 50%;text-align: center;">
          学习乐园
          <div
            style="text-align: center;color: #fff;cursor: pointer;background-color: #06D1ED;width: 30%;margin: 0 auto;height: 2rem;line-height: 2rem;border-radius: 2rem;font-size: 0.8rem;margin-top: 1rem;"
            @click="gotoJoin">获取信息服务</div>
        </div>
      </div>

      <div style="display: flex;flex-wrap: wrap;justify-content: center;padding: 2rem 0;background-color: #fafcff;">
        <div v-for="(item,index) in partList" :key="index" style="width: 25%;padding: 1rem 0;">
          <div style="width: 90%;margin: 0 auto;box-shadow: 0 4px 4px rgba(0, 0, 0, .02);border-radius: 1rem;padding-bottom: 1rem;background-color: #fff;">
            <div>
              <img style="width: 100%;display: block;border-radius: 1rem 1rem 0 0;" :src="item.url" alt="">
            </div>
            <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 90%;margin: 0 auto;margin-top: 0.5rem;">
              {{ item.title }}
            </div>
            <div style="display: flex;justify-content: end;width: 90%;margin: 0 auto;margin-top: 1rem;cursor: pointer;">
              <div style="width: 60%;height: 2rem;border-radius: 2rem;line-height: 2rem;border: 0.1rem solid #1966ff;color: #1966ff;text-align: center;font-size: 0.9rem;" @click="gotoLogin">*50元注册立即学习</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {
        partList: [{
          title: '一图看懂生活服务周边爆品打造核心要点及热点趋势',
          url: require('../../public/img/trends/part1.png'),
        },{
          title: '做好内容和营销，激发品牌搜索新增量',
          url: require('../../public/img/trends/part2.png'),
        },{
          title: '快消品牌鏖战，用好经营策略，才能降本增效、四两拨千斤。',
          url: require('../../public/img/trends/part3.png'),
        },{
          title: '助力企业在货架场、自播、短视频、达播、广告营销等方面快速转化跃迁，实现GMV的快速提升！',
          url: require('../../public/img/trends/part4.png'),
        },{
          title: '当您店铺推出有价格优势的商品，又担心被黄牛党扫货，怎么办？怎么办？怎么办？',
          url: require('../../public/img/trends/part5.png'),
        },{
          title: '什么是商品价格力，如何提升商品价格力',
          url: require('../../public/img/trends/part6.png'),
        },{
          title: '大健康风潮下，消费需求延展，新健康、新味觉、新熟品、新包装等新形态如何快速迭代。',
          url: require('../../public/img/trends/part7.png'),
        },{
          title: '如何提前预知哪类商品卖得好、哪些内容能成爆款？',
          url: require('../../public/img/trends/part8.png'),
        }]
      };
    },

    methods: {
      routerPush(num) {
        this.$router.push({ path: '/trendsNum', query: { id: num } })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      },
      gotoLogin() {
        this.$router.push({ path: '/login' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w90 {
    width: 90%;
  }

  .w60 {
    width: 60%;
  }

  .w30 {
    width: 30%;
  }

  .w200 {
    width: 200px;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop20 {
    margin-top: 20px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .paddingtop20 {
    padding-top: 20px;
  }

  .title {
    color: #000
  }

  .title:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;

    height: 320px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  .textAlign_justify {
    text-align: justify;
  }

  .whitespace {
    white-space: pre-wrap;
    line-height: 30px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .w300 {
      width: 300px;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .marginleft20 {
      margin-left: 0;
    }

    .title {
      color: #bc9158;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>