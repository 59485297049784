<template>
  <div class="minwidth w100 mw100" style="position: relative;background-color: #fff;">
    <tabbar></tabbar>

    <!-- <carousel2></carousel2> -->

    <div style="width: 80%;margin: 0 auto;display: flex;justify-content: space-between;align-items: center;margin-top: 2rem;"
      class="mw100">
      <div style="width: 35%;">
        <img style="width: 100%;border-radius: 0.5rem;" :src="require('../../public/img/partner/about.png')" alt="">
      </div>
      <div style="width: 60%;">
        <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;">关于我们</div>
        <div style="font-size: 0.9rem;color: #999999;">ABOUT US</div>
        <div
          style="color: #666666;font-size: 1.2rem;text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
          格甜信息科技有限公司成立于2021年9月。专注于数字增值产品的服务公司，客户涵盖：互联网企业，运营商行业，通信运营商（联通、电信、移动）等，致力于在互联网数字商品提供全方位的服务。核心业务包括产品策划、广告投放、大数据中台和内容提供方，帮助广告主实现产品增值和GMV增长。我们拥有一支专业的团队，具备丰富的行业经验和深厚的专业技能。我们的目标是通过提供优质的服务和创新的解决方案，成为广告投放领域和数字产品消费服务商的佼佼者。致力于与客户建立长期稳定的合作关系，共同推动互联网广告行业的发展。
        </div>
        <div style="color: #666666;font-size: 1.2rem;text-indent: 2em;line-height: 2rem;text-align: justify;">
          我们致力于成为最安全聪明的数字产品消费服务商。格甜联接起海量数字商品品牌方及运营内容，通过持续优化迭代智能化商业模式及技术安全策略，为合作伙伴提供各类更具性价比数字商品增值服务，为消费者提供丰富、便捷、优质的数字商品。
        </div>
        <div style="color: #666666;font-size: 1.2rem;text-indent: 2em;line-height: 2rem;text-align: justify;">
          我们以产品精细化运营，用户体验为出发点。格甜帮助合作伙伴搭建互联互通的数字化产品桥梁，高效联接各方资源，促进数字产品高效流转。并通过不断开拓全新的营销方案、业务模式和产品组合，为数字化商业的持续繁荣创造价值。
        </div>
        <div style="display: flex;justify-content: space-around;align-items: center;width: 100%;margin-top: 1rem;">
          <div style="width: 20%;background-color: #2550DB;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            产品策划
          </div>
          <div style="width: 20%;background-color: #0FC9FF;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            广告投放
          </div>
          <div style="width: 20%;background-color: #2550DB;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            大数据中台
          </div>
          <div style="width: 20%;background-color: #0FC9FF;text-align: center;height: 4rem;line-height: 4rem;color: #fff;font-size: 1.2rem;font-weight: bold;border-radius: 0.3rem;">
            内容提供方
          </div>
        </div>
      </div>
    </div>

    <div>
      <div style="font-size: 2rem;font-weight: bolder;text-align: center;color: #111111;margin-top: 3rem;">团队介绍</div>
      <div style="font-size: 1rem;text-align: center;color: #999999;">TEAM INTRODUCTION</div>
      <div style="width: 80%;margin: 0 auto;margin-top: 1rem;color: #666666;font-size: 1.2rem;line-height: 2.1rem;text-align: justify;text-indent: 2em;">
        上海格甜信息科技有限公司总部位于上海，专注于互联网产品推广及运营商行业服务，业务覆盖全国，与头部互联网媒体及知名应用建立了深度合作关系。公司团队聚集了国内顶尖的互联网产品、研发及运营人才，具备丰富的通信产品推广运营经验。通过精准洞察行业痛点，持续探索数字化转型路径，为运营商实现高效商业增长提供强力支持。
      </div>
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;border-radius: 0.5rem;">
        <img style="width: 35%;border-radius: 0.5rem 0 0 0.5rem;" :src="require('../../public/img/partner/work1.png')" alt="">
        <div style="width: 60%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;">{{ '专业化与年轻化' }}</div>
          <div style="margin-top: 2rem;line-height: 2rem;text-align: justify;">{{ '团队成员年轻且富有成长性，90%以上拥有本科及以上学历，核心成员多毕业于211/985重点院校，其中研究生占比超过6%。' }}
          </div>
        </div>
      </div>
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;border-radius: 0.5rem;">
        <div style="width: 60%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;">{{ '技术与创新' }}
          </div>
          <div style="margin-top: 2rem;line-height: 2rem;text-align: justify;">{{ '专注于人工智能、大数据处理等高新技术领域，打造了一支数十人的技术攻坚团队，确保在移动互联网营销领域的技术领先地位。' }}</div>
        </div>
        <img style="width: 35%;border-radius: 0 0.5rem 0.5rem 0;" :src="require('../../public/img/partner/work2.png')" alt="">
      </div>
      <div
        style="display: flex;width: 70%;margin: 0 auto;justify-content: space-between;align-items: center;background-color: #f8fafc;margin-top: 3vw;border-radius: 0.5rem;">
        <img style="width: 35%;border-radius: 0.5rem 0 0 0.5rem;" :src="require('../../public/img/partner/work3.png')" alt="">
        <div style="width: 60%;padding: 2rem;letter-spacing: 0.1rem;">
          <div style="margin-top: 1rem;">{{ '全方位服务能力' }}
          </div>
          <div style="margin-top: 1rem;line-height: 2rem;text-align: justify;">{{ '具备优秀的产品推广、技术支持和客户服务能力，帮助合作伙伴实现营销转化最大化。凭借专业团队与创新能力，格甜科技将继续为行业客户提供卓越服务，助力运营商实现商业价值飞跃。' }}</div>
        </div>
      </div>
    </div>

    <div>
      <div style="font-size: 2rem;font-weight: bolder;text-align: center;color: #111111;margin-top: 3rem;">公司历程</div>
      <div style="font-size: 1rem;text-align: center;color: #999999;">COMPANY HISTORY</div>
      <div style="width: 50%;margin: 0 auto;margin-top: 2rem;">
        <el-timeline>
          <el-timeline-item timestamp="2021年09月" placement="top">
            <el-card>
              <h3>上海格甜信息科技有限公司成立</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022年2月" placement="top">
            <el-card>
              <h3>内容服务中台</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2022年5月" placement="top">
            <el-card>
              <h3>中国联通内容服务提供商</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2023年2月" placement="top">
            <el-card>
              <h3>在线服务C端用户突破1100W大关</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2023年3月" placement="top">
            <el-card>
              <h3>上线AI算力推荐推广服务</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2024年3月" placement="top">
            <el-card>
              <h3>以头部投放公司达成推广合作</h3>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="至今" placement="top">
            <el-card>
              <h3>与字节跳动、支付宝、微信、整合市场定制化推广</h3>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>

    <div style="background-color: #f8fafc;margin-top: 3rem;padding-bottom: 2rem;">
      <div style="font-size: 1.5rem;text-align: center;color: #313131;padding-top: 2rem;">
        合作伙伴300+知名企业的优质合作选择
      </div>
      <div style="font-size: 1rem;text-align: center;color: #666;margin-top: 1rem;">
        格甜信息持续拓展业务版图，为内容推广数字化转型和业务增长提供全方位支持，推动行业高效发展。
      </div>
      <div style="display: flex;flex-wrap: wrap;width: 80%;margin: 0 auto;align-items: center;margin-top: 1rem;">
        <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;">
          <img style="width: 100%;" :src="require(`../../public/img/partner/partner0.png`)" alt="">
        </div>
      </div>
    </div>

    <!-- <div style="width: 80%;margin: 0 auto;margin-top: 5rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">企业文化</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">CORPORATE CULTURE</div>
    </div>
    <div style="display: flex;width: 80%;margin: 0 auto;justify-content: space-around;align-items: stretch;padding-bottom: 3rem;margin-top: 2rem;">
      <div style="width: 30%;text-align: center;background-color: #eceef0;border-radius: 1rem;padding-top: 2rem;">
        <div style="height: 4rem;line-height: 4rem;font-size: 1.4rem;">
          企业愿景
        </div>
        <div style="margin-top: 1rem;">
          <img style="width: 30%;" :src="require(`../../public/img/partner/company1.png`)" alt="">
        </div>
        <div style="font-size: 1rem;width: 80%;margin: 0 auto;line-height: 2rem;margin-top: 2rem;margin-top: 3rem;">
          成为运营商行业数字化转型的引领者，<br>
          打造移动互联网营销服务的领先品牌。
        </div>
      </div>
      <div style="width: 30%;text-align: center;background-color: #eceef0;border-radius: 1rem;padding-top: 2rem;">
        <div style="height: 4rem;line-height: 4rem;font-size: 1.4rem;">
          企业价值观
        </div>
        <div style="margin-top: 1rem;">
          <img style="width: 30%;" :src="require(`../../public/img/partner/company2.png`)" alt="">
        </div>
        <div style="font-size: 1rem;width: 70%;margin: 0 auto;line-height: 2rem;margin-top: 2rem;padding-bottom: 1rem;text-align: left;">
          客户第一：关注需求，创造价值。<br>
          创新为本：技术领先，持续突破。<br>
          协作共赢：团队合作，共创未来。<br>
          诚信专业：用实力赢得信任。<br>
        </div>
      </div>
      <div style="width: 30%;text-align: center;background-color: #eceef0;border-radius: 1rem;min-width: 0;padding-top: 2rem;">
        <div style="height: 4rem;line-height: 4rem;font-size: 1.4rem;">
          企业文化
        </div>
        <div style="margin-top: 0.7rem;">
          <img style="width: 30%;" :src="require(`../../public/img/partner/company3.png`)" alt="">
        </div>
        <div style="font-size: 1rem;width: 60%;margin: 0 auto;line-height: 2rem;padding-bottom: 1rem;margin-top: 3rem;">
          创新驱动，客户至上，<br>
          团队协作，共同成长。<br>
        </div>
      </div>
    </div> -->

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {
        typeIndex: 0
      };
    },
  };
</script>

<style scoped>
  .outbody {
    background-color: #1D1D1D;
    min-height: 1000px;
    margin: 0;
    font-family: 'Droid Sans', sans-serif;

    &:before {
      content: '';
      position: fixed;
      top: 0px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      width: 4px;
      background-color: #fff;
    }

    .entries {
      width: calc(100% - 80px);
      max-width: 800px;
      margin: auto;
      position: relative;
      left: -5px;

      .entry {
        width: calc(50% - 80px);
        float: left;
        padding: 20px;
        clear: both;
        text-align: right;

        &:not(:first-child) {
          margin-top: -60px;
        }

        .title {
          font-size: 32px;
          margin-bottom: 12px;
          position: relative;
          color: #fff;

          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border: 4px solid #ffffff;
            background-color: #1D1D1D;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: -73px;
            z-index: 1000;
          }

          &.big:before {
            width: 24px;
            height: 24px;
            transform: translate(8px, -50%);
          }
        }

        .body {
          color: #aaa;

          p {
            line-height: 1.4em;
          }
        }

        &:nth-child(2n) {
          text-align: left;
          float: right;

          .title {
            &:before {
              left: -63px;
            }

            &.big:before {
              transform: translate(-8px, -50%);
            }
          }
        }
      }
    }
  }


  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w80 {
    width: 80%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .margincent {
    margin: 0 auto;
  }

  .positionrel {
    position: relative;

    height: 440px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>