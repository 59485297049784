import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Partner from '../views/Partner.vue'
import Trends from '../views/Trends.vue'
import TrendsNum from '../views/TrendsNum.vue'
import Join from '../views/Join.vue'
import AI from '../views/AI.vue'
import Honour from '../views/Honour.vue'
import Essay210309 from '../views/Essay/210309.vue'
import Essay201128 from '../views/Essay/201128.vue'
import Essay201110 from '../views/Essay/201110.vue'
import Essay200921 from '../views/Essay/200921.vue'
import Jobs from '../views/Jobs.vue'
import News from '../views/news.vue'
import NewsDetail from '../views/newsDetail.vue'
import Login from '../views/login.vue'
import Register from '../views/register.vue'
import Learn from '../views/Learn.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    component: Main
  },
  {
    path: '/learn',
    component: Learn
  },
  {
    path: '/partner',
    component: Partner
  },
  {
    path: '/trends',
    component: Trends
  },
  {
    path: '/trendsNum',
    component: TrendsNum
  },
  {
    path: '/join',
    component: Join
  },
  {
    path: '/news',
    component: News
  },
  {
    path: '/newsDetail',
    component: NewsDetail
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/ai',
    component: AI
  },
  {
    path: '/honour',
    component: Honour
  },
  {
    path: '/210309',
    component: Essay210309
  },
  {
    path: '/201128',
    component: Essay201128
  },
  {
    path: '/201110',
    component: Essay201110
  },
  {
    path: '/200921',
    component: Essay200921
  },
  {
    path: '/jobs',
    component: Jobs
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router