<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        行业咨询
      </div>
    </div>

    <div v-if="num == 0" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        蛇年春晚官宣！主题、主标识发布，又要过年啦
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news0.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        巳巳如意 生生不息
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        承古拓今 饱含深意
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        蛟龙去，灵蛇来。11月29日，中央广播电视总台《2025年春节联欢晚会》发布主题和主标识。乙巳蛇年春晚将在欢乐吉祥、喜气洋洋的总基调中，以“巳巳如意，生生不息”为主题，与全球华人相约除夕、欢度农历新年。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        巳，象征着阳气巳出，阴气巳藏，万物见，成文章。而将两个“巳”字对称摆放，则恰似中国传统的如意纹样。双巳合璧，事事如意，这是乙巳蛇年与如意之间吉祥曼妙的创意链接，饱含喜庆美满的家国祝福，更彰显着中华民族精神根脉生生不息的时代力量。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        总台蛇年春晚将在辞旧迎新的除夕夜，为全球华人献上巳巳如意的祝福，共同迎接一个温暖、欢乐的乙巳蛇年。
      </div>
    </div>


    <div v-if="num == 1" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        麦当劳中国与菜鸟共推“一箱一码”，餐饮供应链迎来数字化新突破
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        近日，麦当劳中国与菜鸟携手，在中国国际供应链促进博览会上共同揭晓了一项智慧供应链的新突破——“一箱一码”项目。这一创新技术是在与麦当劳中国供应商伙伴的紧密合作下，由麦当劳中国发起，菜鸟提供物联网技术支持，多家供应商共同参与推进的。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        据了解，“一箱一码”技术已在麦当劳中国的上游工厂、物流中心及餐厅等多个环节成功部署，并在部分试点市场的麦当劳餐厅完成了全货品全链路的批量验证。这项技术通过RFID（射频识别）及其他物联网技术，在包装上为每件货品赋予了一个独一无二的“数字身份证”，极大地提升了供应链上下游的数字化协同能力和精细管理水平。
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news1.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        随着项目的深入推进，麦当劳中国与菜鸟的合作成果将逐步在全国范围内铺开。预计将有100家供应商工厂接入该项目，加速其在麦当劳中国供应链系统内的复制和推广。这一举措不仅标志着麦当劳中国在智慧供应链建设上的重要一步，也为中国餐饮行业的数字化转型树立了新的标杆。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;;text-align: justify;">
        作为国内餐饮行业首个大规模落地的“一箱一码”数字化实践，麦当劳中国的这一创新不仅提升了自身供应链的效率和准确性，更为整个行业的发展提供了新的思路。通过“一箱一码”技术，麦当劳中国能够实现对货品的精准追踪和高效管理，确保每一件货品从生产到消费的全过程都可控、可追溯。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        截至目前，麦当劳中国已在全国范围内开设了超过6500家餐厅，每年服务的物流车辆超过2000辆，运输距离累计超过8000万公里，相当于绕地球2000圈。如此庞大的供应链体系，对于任何一家企业来说都是一项巨大的挑战。然而，通过引入“一箱一码”等数字化技术，麦当劳中国正在逐步克服这些挑战，为消费者提供更加优质、便捷的服务。
      </div>
    </div>


    <div v-if="num == 2" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        华为与平安举行鸿蒙原生应用上架仪式：35款APP启动开发
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news2.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        【CNMO科技消息】11月29日，华为HarmonyOS官方宣布，今日，华为与平安集团举行鸿蒙原生应用正式上架仪式，平安集团已有35款应用全面启动鸿蒙原生应用开发，其中23款应用已成功上架鸿蒙原生版，这一举措标志着双方在金融服务体验和企业办公效率方面的深入合作。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        据悉，平安集团依托华为HarmonyOS NEXT的创新能力，旨在为客户打造更加便捷易用的综合金融服务体验。这一合作不仅有助于提升平安集团的服务质量和客户体验，同时也进一步丰富了鸿蒙生态的应用场景和生态价值。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        在不久前的11月23日，华为举办了首届鸿蒙生态大会。会上，华为轮值董事长徐直军强调了今年是鸿蒙原生应用生态构建的关键之年。他指出，HarmonyOS
        NEXT作为华为基于OpenHarmony5.0开发的发行版，是鸿蒙生态的重要组成部分。同时，他也欢迎其他公司基于OpenHarmony开发自己的发行版，共同构建基于统一底座的鸿蒙生态。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;;text-align: justify;">
        值得一提的是，华为HarmonyOS NEXT已于今年10月8日开启了公测，首批支持包括华为Mate 60系列、华为Mate X5系列以及华为MatePad Pro 13.2英寸等产品。此外，在11月26日发布的华为Mate
        70系列和华为Mate X6系列折叠屏手机也支持到手升级至HarmonyOS NEXT。
      </div>
    </div>

    <div v-if="num == 3" style="width: 60%;margin: 0 auto;padding-bottom: 2rem;">
      <div
        style="width: 100%;font-size: 2rem;border-bottom: 0.1rem solid #ebecea;line-height: 4rem;margin-top: 2rem;">
        霍金为什么认为外星科技要比地球强？主要原因是它暴露了科技水平
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;margin-top: 2rem;">
        宇宙广阔无垠，自从人类开始探索宇宙之谜以来，一直在寻找地外文明。然而，尽管经过多年的努力，我们依然没有发现任何明确的迹象。人类对外星文明的探索，不仅源于对智慧生命的好奇心，也希望通过与外星文明的交流，获取彼此的技术优势，从而加速自我的科技进步。
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news3.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        然而，并不是每个人都乐见与外星文明的接触。以著名物理学家霍金为例，他曾多次警示人类不要主动与外星生命联系。在他看来，外星文明未必是友好的，由于他们的科技可能远远领先于我们，人类的鲁莽尝试可能带来不可预知的灾难。霍金之所以坚信外星科技优于地球，是因为他们展现出的科技水平已然显示出这一点。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        那么，究竟是怎样的科技令霍金如此确信呢？答案在于速度。宇宙的浩瀚使得距离成为以光年来计的概念。距离我们最近的类地行星也至少有4光年之遥，对现有人类技术来说，这已经是难以跨越的障碍。根据爱因斯坦的相对论，光速被视为任何物体所能达到的极速，而想要突破这一极限几乎不可能。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        光速成了衡量文明成熟度的重要标志，同时也是制约文明发展的瓶颈。如果一个文明无法突破光速，其活动范围将始终局限于有限宇宙空间。比如人类，即使能达到光速，我们的活动范围也局限在太阳系的周边。对于银河系的探索甚至是想跨越到仙女座星系，依然只是遥不可及的愿景。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        此外，我们借助无线电波进行星际通信，这种方式的有效范围及其有限，超出100光年，信号基本会消失殆尽。但如果某种外星文明能够接收到这些信号，则意味着他们的科技水平远胜于我们。而如果他们甚至能复原已经消散的无线电波，这样的能力着实令人生畏。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        高等外星文明在通讯技术上，必然远超于我们的无线电技术。他们或许拥有比我们先进得多的通信方式，实现跨越数百光年的即时通信。的确，对于那些在各方面超过我们的文明来说，跨越100光年距离造访地球并非难事，这也意味着他们很可能已经掌握了超光速航行能力。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        反过来看，如果是距离100光年以内的外星文明接收到了我们的信号，很可能他们的科技水平与我们相近。因此，他们也难以抵达地球。这100光年，成为我们与其他文明间的不可逾越的障碍。即使对于我们在宇宙中发现的许多无线电信号，有些被怀疑是来源于其他星际文明，但仅距离10光年的情况下，我们依然无能为力，光年是个无形的屏障。
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news4.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        如果真的有外星文明能降临地球，他们的科技必然远超于地球。在这种情况下，这对人类是福是祸呢？外星生命为何要千里迢迢拜访地球？仅仅是来观光或交朋友吗？多数人对此表示怀疑。如果此类情况反之而行，当人类可以实现超光速飞行，发现了距离100光年外的智慧行星，我们的动机是什么？是去游历，还是进行技术共享？
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        显然，这些都不是。人类若派遣宇宙飞船前往，目的极可能是出于扩张或探察对方的实力。而当科技差距显著之际，合作对话也将被武力征服所取代，这无异于宇宙文明间的生存法则。
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        这些考虑解释了为何霍金坚决反对人类与外星接触。光速限制对于一个文明的对外扩张至关重要，也标志着从低级向高级文明的过渡。所有可以抵达地球的外星文明至少具备光速甚至超光速旅行的能力，如果他们对人类抱有敌意，我们又拿什么来抵御呢？
      </div>
      <div style="width: 100%;background-color: #ebecea;text-align: center;border-radius: 1rem;margin-top: 2rem;">
        <img style="width: 95%;" :src="require('../../public/img/news/news5.png')" alt="">
      </div>
      <div style="text-indent: 2em;line-height: 2rem;text-align: justify;">
        因此，人类应选择低调发展，待突破光速技术时，再来考虑对外扩张。我们需要的是实力，只有那时我们才能自信地在宇宙中探索其他文明。那么，大家认为如何呢？
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        num: 0,
      };
    },

    methods: {},

    mounted() {
      this.num = this.$route.query.num;
    },
  };
</script>

<style scoped>
  .w100 {
    width: 100%;
  }

  .w60 {
    width: 60%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .textalign_justify {
    text-align: justify;
    line-height: 25px;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize30 {
    font-size: 30px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize16 {
    font-size: 16px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot70 {
    margin-bottom: 70px;
  }

  #job:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;
    height: 340px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>