<template>
  <div class="minwidth w100 mw100" style="background-color: #fff;">
    <tabbar></tabbar>

    <div>
      <div class="positionrel w100">
        <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
        <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);width: 50%;text-align: center;">
          行业服务案例
          <div
            style="text-align: center;color: #fff;cursor: pointer;width: 100%;margin: 0 auto;height: 2rem;line-height: 2rem;border-radius: 2rem;font-size: 1.2rem;margin-top: 1rem;">获取营销策划解决方案会员优惠价：5000元起在线预约方案
          </div>
          <div
            style="text-align: center;color: #fff;cursor: pointer;background-color: #06D1ED;width: 30%;margin: 0 auto;height: 2rem;line-height: 2rem;border-radius: 2rem;font-size: 0.8rem;margin-top: 1rem;"
            @click="gotoJoin">获取信息服务</div>
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 2rem;text-align: center;color: #313131;padding-top: 2rem;font-weight: bold;">
          产品服务-权益供应能力
        </div>
        <div style="font-size: 1.2rem;text-align: left;color: #666;margin-top: 1rem;line-height: 2.5rem;">
          通过融合线上线下场景布局，紧密对接运营商需求，为广大企业量身定制“智能化运营”服务，让服务延伸到消费者日常的生活，围绕“衣、食、住、行”等各方面的消费需求进行场景构造。
        </div>
        <div style="font-size: 1.8rem;text-align: left;margin-top: 1rem;line-height: 2rem;cursor: pointer;" @click="gotoLogin">
          <span style="font-weight: bolder;">获取解决方案：</span>
          <span style="font-size: 1.5rem;">定制信息服务方案【低至50起】</span>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;">智慧场景<br></span>
          多场景自由组合<br>
          涵盖美食外卖、 优惠加油、 特惠观影、 出行旅游、 听书等<br>
          根据客户需求搭建<br>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;font-size: 1.2rem;font-weight: bold;line-height: 5rem;height: 5rem;">
          <div style="flex: 1;">特色专区</div>
          <div style="flex: 1;">城市专区</div>
          <div style="flex: 1;">教育专区</div>
          <div style="flex: 1;">贵宾客户专区</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;text-align: center;">
          <div style="flex: 1;cursor: pointer;" @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place1.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place2.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place3.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place4.png')" />
          </div>
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoLogin">
          定制信息服务方案【低至50起】
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;">
        <div style="font-size: 2rem;text-align: center;color: #313131;padding-top: 3rem;font-weight: bold;">
          行业解决方案-积分体系
        </div>
        <!-- <div style="font-size: 1rem;text-align: left;color: #666;margin-top: 1rem;line-height: 1.5rem;">
          通过融合线上线下场景布局，紧密对接运营商需求，为广大企业量身定制“智能化运营”服务，让服务延伸到消费者日常的生活，围绕“衣、食、住、行”等各方面的消费需求进行场景构造。
        </div> -->
        <div style="font-size: 1.8rem;text-align: left;margin-top: 1rem;line-height: 2rem;margin-top: 2rem;" @click="gotoLogin">
          <span style="font-weight: bolder;">获取解决方案：</span>
          <span style="font-size: 1.5rem;">定制信息服务方案【低至50起】</span>
        </div>
        <div
          style="font-size: 1rem;line-height: 2.2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;">积分运营体系<br></span>
          ① 不断扩展权益品类涵盖餐饮美食、影音娱乐等八大类，为用户提供更多选择；<br>
          ② 通过引流平台推广、群发短信、微信通知、APP推送等推广形式宣传积分商城，引导用户参与兑换；<br>
          ③ 依据主题设计商城，推荐上架商品。如3-4月踏春季，可推荐酒店、租车、各地旅游、美食券等；<br>
          ④ 集合免费/特价商品，打造每月尊享权益日，如0积分购物、分享购等形式。<br>
        </div>
        <div style="font-size: 1rem;line-height: 2.2rem;text-align: justify;margin-top: 2rem;">
          积分可兑换：视听会员、酒店优惠券、美食满减券、加油券、停车券、旅行景区门票、支付宝红包、微信红包、美团立减金、拼多多优惠券等，赋予积分现金的价值，提升用户的积分兑换率。
        </div>
        <div style="text-align: center;">
          <img style="width: 90%;" :src="require('../../public/img/trends/place5.png')" alt="">
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoLogin">
          定制信息服务方案【低至50起】
        </div>
      </div>

      <div style="width: 80%;margin: 0 auto;padding-bottom: 2rem;">
        <div style="font-size: 2rem;text-align: center;color: #313131;padding-top: 2rem;font-weight: bold;">
          行业解决方案-个性化推广
        </div>
        <!-- <div style="font-size: 1.2rem;text-align: left;color: #666;margin-top: 1rem;line-height: 1.5rem;">
          通过融合线上线下场景布局，紧密对接运营商需求，为广大企业量身定制“智能化运营”服务，让服务延伸到消费者日常的生活，围绕“衣、食、住、行”等各方面的消费需求进行场景构造。
        </div> -->
        <div style="font-size: 1.8rem;text-align: left;margin-top: 1.5rem;line-height: 2rem;cursor: pointer;" @click="gotoLogin">
          <span style="font-weight: bolder;">获取解决方案：</span>
          <span style="font-size: 1.5rem;">定制信息服务方案【低至50起】</span>
        </div>
        <div
          style="font-size: 1rem;line-height: 2rem;text-align: justify;background-color: #f8fafc;padding: 1rem;border-radius: 1rem;margin-top: 1rem;">
          <span style="font-weight: bolder;">优秀推广案例介绍<br></span>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;text-align: center;margin-top: 1rem;">
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place6.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place7.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place8.png')" />
          </div>
          <div style="flex: 1;cursor: pointer;"  @click="gotoJoin">
            <img style="width: 90%;" :src="require('../../public/img/trends/place9.png')" />
          </div>
        </div>
        <div
          style="display: flex;justify-content: space-between;align-items: center;text-align: center;font-size: 1rem;font-weight: bold;line-height: 3rem;">
          <div style="flex: 1;">
            美食-必胜客<br>
            美食汇年卡<br>
            拉新必胜客APP小程序 12W户<br>
            <div
              style="text-align: center;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
              @click="gotoJoin">
              获取解决方案 >
            </div>
          </div>
          <div style="flex: 1;">
            美食-星巴克<br>
            这一杯有心意更有星意<br>
            成功引流 30W+户<br>
            <div
              style="text-align: center;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
              @click="gotoJoin">
              获取解决方案 >
            </div>
          </div>
          <div style="flex: 1;">
            互联网-短剧<br>
            一起炸翻天<br>
            拉新趣头条APP 10W+户<br>
            <div
              style="text-align: center;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
              @click="gotoJoin">
              获取解决方案 >
            </div>
          </div>
          <div style="flex: 1;">
            贝乐虎-教育<br>
            贝乐虎<br>
            拉新贝乐虎APP 50W+户<br>
            <div
              style="text-align: center;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
              @click="gotoJoin">
              获取解决方案 >
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  export default {
    data() {
      return {

      };
    },

    methods: {
      routerPush(num) {
        this.$router.push({ path: '/trendsNum', query: { id: num } })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      },
      gotoLogin() {
        this.$router.push({ path: '/login' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .w100 {
    width: 100%;
  }

  .w90 {
    width: 90%;
  }

  .w60 {
    width: 60%;
  }

  .w30 {
    width: 30%;
  }

  .w200 {
    width: 200px;
  }

  .minwidth {
    min-width: 1000px;
  }

  .flex {
    display: flex;
  }

  .textcent {
    text-align: center;
  }

  .fontsize50 {
    font-size: 50px;
  }

  .fontsize20 {
    font-size: 20px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .fontsize12 {
    font-size: 12px;
  }

  .fontcolorb0 {
    color: #b0b0b0;
  }

  .margincent {
    margin: 0 auto;
  }

  .margincentY {
    margin: auto 0;
  }

  .margintop20 {
    margin-top: 20px;
  }

  .margintop50 {
    margin-top: 50px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginleft20 {
    margin-left: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .paddingtop20 {
    padding-top: 20px;
  }

  .title {
    color: #000
  }

  .title:hover {
    color: #bc9158;
  }

  .positionrel {
    position: relative;

    height: 320px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 1920px;
    -webkit-filter: contrast(50%);
    filter: contrast(50%);
  }

  .textAlign_justify {
    text-align: justify;
  }

  .whitespace {
    white-space: pre-wrap;
    line-height: 30px;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .mw100 {
      width: 100vw;
    }

    .flexnone {
      display: block;
    }

    .w80 {
      width: 80%;
    }

    .w300 {
      width: 300px;
    }

    .fontsize30 {
      font-size: 30px;
    }

    .marginleft20 {
      margin-left: 0;
    }

    .title {
      color: #bc9158;
    }

    .positionrel {
      position: relative;

      height: 179px;
      overflow: hidden;
    }

    .positionabs {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      width: 780px;
    }
  }
</style>