<template>
  <div class="bg w100 font16 paddingtop20 mw100 font12">
    <div class="flex flex-jcbet w80 margincenter minwidth flexnone">
      <div style="flex: 1;text-align: center;padding-top: 3rem;">
        <img :src="logoblack" alt="" class="logoblack" />
      </div>
      <div style="flex: 1;">
        <div
          v-for="(item) in skipList"
          :key="item.name"
          class="margintop10"
          style="text-align: center;"
        >
          <el-button type="text" class="font16 mfont14" @click="skip(item.url)">{{ item.name }}</el-button>
        </div>
      </div>
      <div style="flex: 1;">

        <div class="margintop10 mmargintop20">联系我们</div>
        <div
          v-for="item in informationList"
          :key="item.msg"
          class="flex margintop10"
        >
          <img :src="item.icon" alt="" class="icon margincentY" />
          <div class="marginleft msg">{{ item.msg }}</div>
        </div>
      </div>
      <!-- <div>
        <div class="margintop10">扫一扫，关注我们</div>
      </div> -->
    </div>

    <div class="margintop20 textcent">
      技术支持：上海格甜信息科技有限公司
    </div>

    <div class="margintop20 textcent fontmsg paddingbot20" style="cursor: pointer;color: #fff;" @click="bottomSkip()">
      Copyright ©上海格甜信息科技有限公司 沪ICP备2021034167号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoblack: require("../../public/img/tabbar/logoComposite1.png"),

      skipList: [
        { name: "首页", url: "/" },
        { name: "关于我们", url: "/partner" },
        { name: "学习乐园", url: "/learn" },
        { name: "行业服务案例", url: "/trends" },
        { name: "联系我们", url: "/join" }
      ],

      informationList: [
        {
          msg: "4000006502",
          icon: require("../../public/img/footer/footer_icon.png"),
        },
        {
          msg: "getian2023@126.com",
          icon: require("../../public/img/footer/footer_icon2.png"),
        },
        {
          msg: "上海市崇明区庙镇宏海公路2050号（上海庙镇经济开发区）",
          icon: require("../../public/img/footer/footer_icon3.png"),
        },
      ],
    };
  },

  methods: {
    skip(url) {
      this.$router.push(url);
    },
    bottomSkip(){
      location.href='https://beian.miit.gov.cn/'
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #fff;
}

.bg {
  background-color: #343434;
}

.font16 {
  color: #ffffff;
  font-size: 16px;
}

.fontmsg {
  color: #75767a;
}

.textcent {
  text-align: center;
}

.icon {
  width: 10px;
}

.flex {
  display: flex;
}

.flex-jcbet {
  justify-content: space-between;
}

.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.margincenter {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop10 {
  margin-top: 10px;
}

.margintop20 {
  margin-top: 20px;
}

.marginleft {
  margin-left: 10px;
}

.paddingtop20 {
  padding-top: 20px;
}

.logoblack {
  height: 150px;
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .flexnone {
    display: block;
  }

  .mw100 {
    width: 100vw;
  }

  .fontmsg {
    font-size: 12px;
  }

  .font12 {
    font-size: 12px;
  }

  .mfont14 {
    font-size: 14px
  }

  .logoblack {
    height: 80px;
  }

  .mmargintop20 {
    margin-top: 20px;
  }
}
</style>