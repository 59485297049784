<template>
  <div style="position: relative;background-color: #fff;" class="w100 minwidth mw100">
    <tabbar style="position: relative; z-index: 1;"></tabbar>

    <!-- <div>
      <carousel style="position: relative;z-index: 0; width: 100%"></carousel>
    </div> -->


    <div style="width: 100%;margin: 0 auto;position: relative;">
      <img style="width: 100%;height: auto;" :src="require('../../public/img/main/picMain_1.png')" alt="">
      <!-- <div style="position: absolute;top: 60%;left: 50%;transform: translate(-50%);width: 30%;height: 5rem;line-height: 5rem;text-align: center;border: 4px solid transparent;border-radius: 16px;cursor: pointer;background-clip: padding-box, border-box;background-origin: padding-box, border-box;background-image: linear-gradient(to right, #fff, #fff), linear-gradient(90deg, #8F41E9, #578AEF);color: #5F80EA;letter-spacing: 1rem;font-size: 2rem;font-weight: bold;" @click="gotoJoin">
          获取解决方案
        </div> -->
      <div
        style="position: absolute;top: 77%;left: 50%;transform: translate(-50%);width: 27%;height: 5rem;line-height: 5rem;text-align: center;border: 4px solid transparent;border-radius: 16px;cursor: pointer;"
        @click="gotoJoin"></div>
    </div>


    <div style="width: 80%;margin: 0 auto;margin-top: 3rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">关于我们</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">ABOUT US</div>
      <div
        style="margin-top: 1rem;font-size: 1.2rem;line-height: 2.2rem;text-indent: 2em;text-align: justify;background-color: #f8fafc;padding: 2rem;border-radius: 1rem;">
        <div>
          上海格甜信息科技有限公司，成立于2021年9月，是一家以数据智能驱动的创新型科技企业，专注于移动互联网营销服务。
        </div>
        <div>
          秉承“以客户为中心”的核心理念，我们深耕信息服务领域，精准洞察行业趋势与需求，专注解决信息服务企业在公众营销与全客营销领域的关键痛点。通过提供全链路的战略规划与服务解决方案，助力信息服务企业提升市场竞争力与用户体验。
        </div>
        <div
          style="text-align: right;font-size: 1.2rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoPartner">
          了解更多 >
        </div>
      </div>
    </div>
    <div style="width: 80%;margin: 0 auto;margin-top: 5rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">核心业务</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">CORE BUSINESS</div>
    </div>
    <div style="margin-top: 2rem;color: #555555;margin-bottom: 3rem;">
      <div
        style="width: 80%;margin: 0 auto;letter-spacing: 0.1rem;font-weight: bolder;display: flex;justify-content: flex-start;align-items: center;" @click="gotoJoin">
        <div style="font-size: 3rem;color: #06d1ed;">
          Ⅰ
        </div>
        <div>
          <div style="font-size: 1.2rem;">推广行业数字营销解决方案</div>
          <div style="font-size: 1rem;">DIGITAL MARKETING</div>
        </div>
      </div>
      <div
        style="width: 80%;margin: 0 auto;text-align: justify;line-height: 2rem;font-size: 1.2rem;margin-top: 1rem;text-indent: 2em;">
        <div>
          格甜信息以智能化赋能信息服务企业，提供全面的“智能运营”解决方案。通过人工智能与在线客服系统的深度结合，实现7×24小时随时响应，全面助力运营商行业数字化升级。我们专注于信息服务需求，打造专业化运营体系，从场景化营销策划、技术开发到活动执行、数据监控、复盘优化，全流程满足企业多样化需求。以数据为驱动，精准覆盖引流、获客、促活、留存、对账等核心场景，为企业提供更高效的运营支持。
        </div>
        <div>
          整合生活服务类优质品牌资源，格甜信息通过会员体系、优惠券、支付立减、积分兑换等多维度创新营销玩法，助力企业优化用户体验。同时，利用H5短剧、特色专区、节日活动等媒介，打造故事化、礼品化的内容生态，增强客户粘性，构建私域营销与运营的闭环体系。以专业信息服务能力为核心，格甜信息致力于提升品牌热度与市场影响力，为企业实现从服务到价值的全面升级。
        </div>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoTrends">
          查看详情 >
        </div>
      </div>
      <img style="width: 80%;display: block;margin: 0 auto;margin-top: 0rem;"
        :src="require('../../public/img/main/digitize.png')" alt="">
    </div>

    <div style="width: 80%;margin: 0 auto;margin-top: 2rem;">
      <div style="font-size: 2.4rem;font-weight: bolder;color: #111111;text-align: center;">定制化信息服务解决方案</div>
      <div style="font-size: 1rem;color: #999999;text-align: center;">INFORMATION SERVICE SOLUTION</div>
    </div>
    <div style="width: 80%;margin: 0 auto;margin-top: 2rem;text-align: center;line-height: 2rem;font-size: 1.2rem;">
      我们的定制化推广解决方案，通过高效的数据处理与智能分析，提供全面、精准的推广支持，包含以下
    </div>
    <div style="width: 80%;margin: 0 auto;margin-top: 1rem;display: flex;justify-content: space-between;">
      <div style="width: 49.5%;">
        <div style="width: 100%;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block1.png')" alt="">
        </div>
        <div style="width: 100%;margin-top: 1rem;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block2.png')" alt="">
        </div>
      </div>
      <div style="width: 49.5%;">
        <div style="width: 100%;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block3.png')" alt="">
        </div>
        <div style="width: 100%;margin-top: 1rem;">
          <img style="width: 100%;display: block;margin: 0 auto;margin-top: 0rem;"
            :src="require('../../public/img/main/block4.png')" alt="">
        </div>
      </div>
    </div>

    <div style="width: 100%;margin: 0 auto;background-color: #f8fafc;margin-top: 3rem;">
      <div style="width: 80%;margin: 0 auto;font-size: 1.8rem;text-align: center;color: #313131;padding-top: 2rem;">
        完整高效的数据中台是信息服务的基石
      </div>
      <div
        style="width: 80%;margin: 0 auto;font-size: 1rem;text-align: left;color: #666;margin-top: 1rem;line-height: 2rem;text-align: justify;">
        数据平台整体架构围绕数据采集、存储分析、共享和应用四个核心层次展开，为企业提供一站式数据管理和价值转化能力。<br>
        数据采集层：是平台的基础，负责从多源（业务系统、用户行为、IoT设备等）获取数据，支持实时采集（如 Kafka、Flink）和批量采集（如
        Sqoop、Flume）。通过清洗、格式化和脱敏操作，确保数据完整性、安全性和高质量，为后续处理奠定基础。<br>
        数据存储分析层：是平台的核心，提供多种类型数据的存储和管理能力，包括结构化（MySQL）、半结构化（MongoDB）和非结构化（HDFS）存储方案。同时，通过数据湖集中管理原始数据，结合数据仓库（如
        Hive、Snowflake）进行高效建模和分析。分析层支持批处理（如 Spark）和实时流处理（如 Flink），满足大规模数据计算和复杂分析需求。<br>
        数据共享层：实现了跨系统、跨部门的数据流通，通过开放 API 接口和建立主题化的数据集市，使数据消费者能够便捷获取所需资源。采用细粒度权限管理策略，确保敏感数据的安全性和合规性，有效降低数据使用风险。<br>
        数据应用层：是平台价值输出的核心环节，涵盖业务报表、智能分析和自动化决策支持等多种场景。通过 BI 工具（如 Tableau、FineBI）生成直观的可视化报表，利用 AI
        算法实现个性化推荐、用户画像及预测分析，提升运营效率和用户体验。<br>
        整个架构设计具备高扩展性、高安全性和高可用性，支持企业从数据采集到应用的全流程管理，帮助实现数据驱动的业务转型与精准决策。<br>
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoLogin">
          50元~定制您的信息服务方案 >>>
        </div>
      </div>
      <div style="width: 80%;margin: 0 auto;margin-top: 2rem;">
        <img style="width: 100%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground1.png')"
          alt="">
      </div>
    </div>
    <div style="width: 80%;margin: 0 auto;margin-top: 3rem;padding-bottom: 2rem;">
      <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 3rem;">
        产品服务-推广能力
      </div>
      <div
        style="font-size: 1rem;text-align: left;color: #666;margin-top: 1rem;line-height: 1.5rem;line-height: 2rem;text-align: justify;">
        我们专注于构建高效、精准的业务推广解决方案，通过数据驱动和创新技术赋能，为企业提供全渠道覆盖、深度用户触达的推广服务。无论是品牌曝光、用户增长还是转化提升，我们都能帮助合作伙伴实现营销目标，优化资源投入，最大化推广价值。
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoTrends">
          查看详情 >
        </div>
      </div>
      <div style="width: 100%;margin-top: 1rem;">
        <img style="width: 100%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground2.png')"
          alt="">
      </div>
      <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 2rem;">
        产品服务-权益供应能力
      </div>
      <div
        style="font-size: 1rem;text-align: left;color: #666;margin-top: 1rem;line-height: 1.5rem;line-height: 2rem;text-align: justify;">
        我们是一家专注于权益生态整合与分发的服务提供商，致力于通过技术驱动与资源整合，构建高效、精准、可持续的权益供应体系。我们不仅覆盖通信、消费、出行、娱乐等多元场景，更以智能化的分发能力和个性化推荐技术，为用户提供便捷高效的权益体验，同时助力合作伙伴实现资源最大化利用与用户价值提升。以创新为引擎，我们不断推动权益服务从“工具”向“价值共创”的新高度迈进。
        <div
          style="text-align: center;font-size: 1.5rem;letter-spacing: 0.2rem;color: #5F80EA;margin-top: 1.2rem;cursor: pointer;"
          @click="gotoTrends">
          查看详情 >
        </div>
      </div>
      <div style="width: 100%;margin-top: 1rem;">
        <img style="width: 100%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground3.png')"
          alt="">
      </div>
      <div style="font-size: 1.8rem;text-align: center;color: #313131;padding-top: 2rem;">
        产品服务-产品在线服务
      </div>
      <div
        style="font-size: 1rem;text-align: center;color: #666;margin-top: 1rem;line-height: 1.5rem;line-height: 2rem;text-align: justify;">
        7*12小时400客服热线服务：<br>
        1.我们提供全天候（7天×12小时）客服热线，确保您在任何时间段都能得到及时的帮助。通过我们完善的客服平台系统，用户可以享受以下服务：在线咨询：用户可以通过平台随时发起咨询，获取专业的解答和帮助。<br>
        2.问题反馈：任何问题或疑虑都可以通过反馈功能提交，客服人员会迅速响应并处理。<br>
        3.实时追踪处理状态：用户能够实时查看问题的处理进度，确保问题得到及时解决。<br>
        4.订购流程溯源查询：提供订单追踪功能，用户可随时查询订购过程中的各个环节，确保透明度和安全性。<br>
      </div>
      <div style="width: 100%;margin-top: 1rem;">
        <img style="width: 100%;display: block;margin: 0 auto;" :src="require('../../public/img/main/ground4.png')"
          alt="">
      </div>
    </div>

    <bottom></bottom>

    <!-- <customerservice></customerservice> -->

  </div>
</template>

<script>
  import Tabbar from "../components/Tabbar.vue";
  import Bottom from "../components/Bottom.vue";
  import Carousel from "../components/Carousel.vue";

  export default {
    components: { Tabbar, Bottom, Carousel },
    data() {
      return {
        typeIndex: '0',
        typeList: [
          {
            urlCheck: require(`../../public/img/main/type1_check.png`),
            urlUncheck: require(`../../public/img/main/type1_uncheck.png`),
            text: '智能营销'
          },
          {
            urlCheck: require(`../../public/img/main/type2_check.png`),
            urlUncheck: require(`../../public/img/main/type2_uncheck.png`),
            text: '数据研发'
          },
          {
            urlCheck: require(`../../public/img/main/type3_check.png`),
            urlUncheck: require(`../../public/img/main/type3_uncheck.png`),
            text: '智慧运营'
          },
          {
            urlCheck: require(`../../public/img/main/type4_check.png`),
            urlUncheck: require(`../../public/img/main/type4_uncheck.png`),
            text: '能力供应'
          }
        ],
      };
    },

    methods: {
      skill() {
        window.scrollTo(0, 0);
        this.$router.push("/ai");
      },
      honour() {
        window.scrollTo(0, 0);
        this.$router.push("/honour");
      },

      gotoPartner() {
        this.$router.push({ path: '/partner' })
      },
      gotoTrends() {
        this.$router.push({ path: '/trends' })
      },
      gotoJoin() {
        this.$router.push({ path: '/join' })
      },
      gotoLogin() {
        this.$router.push({ path: '/login' })
      }
    },
  };
</script>

<style scoped>
  * {
    margin: 0;
    padding: 0;
  }

  .bgtype>>>.tabFont {
    color: #ffffff;
  }

  .bgtype>>>.tabFont:hover {
    color: rgb(233 192 131);
  }

  .bgtype>>>.tabListBut {
    color: #ffffff;
  }

  .buttype {
    background-color: transparent;
    border: 0;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  .flex {
    display: flex;
  }

  .flex1 {
    flex: 1;
  }

  .flexcol {
    flex-direction: column;
  }

  .w100 {
    width: 100%;
  }

  .w80 {
    width: 80%;
  }

  .w50 {
    width: 50%;
  }

  .minwidth {
    min-width: 1000px;
  }

  .minheight {
    min-height: 200px;
  }

  .textcent {
    text-align: center;
  }

  .positionrel {
    position: relative;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .margincenter {
    margin: 0 auto;
  }

  .marginauto {
    margin: auto;
  }

  .margintop30 {
    margin-top: 30px;
  }

  .margintop70 {
    margin-top: 70px;
  }

  .marginbot20 {
    margin-bottom: 20px;
  }

  .marginbot40 {
    margin-bottom: 40px;
  }

  .fontsize14 {
    font-size: 14px;
  }

  .subtitle {
    font-size: 35px;
    font-weight: bold;
  }

  .lineheight30 {
    line-height: 30px;
  }

  .pic {
    width: 100%;
    border-radius: 10px;
  }

  .show1 {
    display: block;
  }

  .show2 {
    display: none;
  }

  .textIndent {
    text-indent: 32px;
    text-align: justify;
  }

  @media screen and (max-width: 750px) {
    .minwidth {
      min-width: 0px;
    }

    .flexnone {
      display: block;
    }

    .mw100 {
      width: 100vw;
    }

    .show1 {
      display: none;
    }

    .show2 {
      display: block;
    }
  }
</style>